/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

          var selectors = [];

          var check_binded = false;
          var check_lock = false;
          var defaults = {
            interval: 250,
            force_process: false
          }
          var $window = $(window);

          var $prior_appeared;

          function process() {
            check_lock = false;
            for (var index = 0; index < selectors.length; index++) {
              var $appeared = $(selectors[index]).filter(function() {
                return $(this).is(':appeared');
              });

              $appeared.trigger('appear', [$appeared]);

              if ($prior_appeared) {

                var $disappeared = $prior_appeared.not($appeared);
                $disappeared.trigger('disappear', [$disappeared]);
              }
              $prior_appeared = $appeared;
            }
          }

          // "appeared" custom filter
          $.expr[':']['appeared'] = function(element) {
            var $element = $(element);
            if (!$element.is(':visible')) {
              return false;
            }

            var window_left = $window.scrollLeft();
            var window_top = $window.scrollTop();
            var offset = $element.offset();
            var left = offset.left;
            var top = offset.top;

            if (top + $element.height() >= window_top &&
                top - ($element.data('appear-top-offset') || 0) <= window_top + $window.height() &&
                left + $element.width() >= window_left &&
                left - ($element.data('appear-left-offset') || 0) <= window_left + $window.width()) {
              return true;
            } else {
              return false;
            }
          }

          $.fn.extend({
            // watching for element's appearance in browser viewport
            appear: function(options) {
              var opts = $.extend({}, defaults, options || {});
              var selector = this.selector || this;
              if (!check_binded) {
                var on_check = function() {
                  if (check_lock) {
                    return;
                  }
                  check_lock = true;

                  setTimeout(process, opts.interval);
                };

                $(window).scroll(on_check).resize(on_check);
                check_binded = true;
              }

              if (opts.force_process) {
                setTimeout(process, opts.interval);
              }
              selectors.push(selector);
              return $(selector);
            }
          });

          $.extend({
            // force elements's appearance check
            force_appear: function() {
              if (check_binded) {
                process();
                return true;
              };
              return false;
            }
          });

          '$:nomunge'; // Used by YUI compressor.

          var cache = {},

            // Reused internal string.
            doTimeout = 'doTimeout',

            // A convenient shortcut.
            aps = Array.prototype.slice;


          $[doTimeout] = function() {
            return p_doTimeout.apply( window, [ 0 ].concat( aps.call( arguments ) ) );
          };

          $.fn[doTimeout] = function() {
            var args = aps.call( arguments ),
              result = p_doTimeout.apply( this, [ doTimeout + args[0] ].concat( args ) );

            return typeof args[0] === 'number' || typeof args[1] === 'number'
              ? this
              : result;
          };

          function p_doTimeout( jquery_data_key ) {
            var that = this,
              elem,
              data = {},

              // Allows the plugin to call a string callback method.
              method_base = jquery_data_key ? $.fn : $,

              // Any additional arguments will be passed to the callback.
              args = arguments,
              slice_args = 4,

              id        = args[1],
              delay     = args[2],
              callback  = args[3];

            if ( typeof id !== 'string' ) {
              slice_args--;

              id        = jquery_data_key = 0;
              delay     = args[1];
              callback  = args[2];
            }

            // If id is passed, store a data reference either as .data on the first
            // element in a jQuery collection, or in the internal cache.
            if ( jquery_data_key ) { // Note: key is 'doTimeout' + id

              // Get id-object from the first element's data, otherwise initialize it to {}.
              elem = that.eq(0);
              elem.data( jquery_data_key, data = elem.data( jquery_data_key ) || {} );

            } else if ( id ) {
              // Get id-object from the cache, otherwise initialize it to {}.
              data = cache[ id ] || ( cache[ id ] = {} );
            }

            // Clear any existing timeout for this id.
            data.id && clearTimeout( data.id );
            delete data.id;

            // Clean up when necessary.
            function cleanup() {
              if ( jquery_data_key ) {
                elem.removeData( jquery_data_key );
              } else if ( id ) {
                delete cache[ id ];
              }
            };

            // Yes, there actually is a setTimeout call in here!
            function actually_setTimeout() {
              data.id = setTimeout( function(){ data.fn(); }, delay );
            };

            if ( callback ) {
              // A callback (and delay) were specified. Store the callback reference for
              // possible later use, and then setTimeout.
              data.fn = function( no_polling_loop ) {

                // If the callback value is a string, it is assumed to be the name of a
                // method on $ or $.fn depending on where doTimeout was executed.
                if ( typeof callback === 'string' ) {
                  callback = method_base[ callback ];
                }

                callback.apply( that, aps.call( args, slice_args ) ) === true && !no_polling_loop

                  // Since the callback returned true, and we're not specifically
                  // canceling a polling loop, do it again!
                  ? actually_setTimeout()

                  // Otherwise, clean up and quit.
                  : cleanup();
              };

              actually_setTimeout();

            } else if ( data.fn ) {

              delay === undefined ? cleanup() : data.fn( delay === false );
              return true;

            } else {

              cleanup();
            }

          };




        //CSS3 Animate-it
        $('.animatedParent').appear();
        $('.animatedClick').click(function(){
          var target = $(this).attr('data-target');


          if($(this).attr('data-sequence') != undefined){
            var firstId = $("."+target+":first").attr('data-id');
            var lastId = $("."+target+":last").attr('data-id');
            var number = firstId;

            //Add or remove the class
            if($("."+target+"[data-id="+ number +"]").hasClass('go')){
              $("."+target+"[data-id="+ number +"]").addClass('goAway');
              $("."+target+"[data-id="+ number +"]").removeClass('go');
            }else{
              $("."+target+"[data-id="+ number +"]").addClass('go');
              $("."+target+"[data-id="+ number +"]").removeClass('goAway');
            }
            number ++;
            delay = Number($(this).attr('data-sequence'));
            $.doTimeout(delay, function(){
              console.log(lastId);

              //Add or remove the class
              if($("."+target+"[data-id="+ number +"]").hasClass('go')){
                $("."+target+"[data-id="+ number +"]").addClass('goAway');
                $("."+target+"[data-id="+ number +"]").removeClass('go');
              }else{
                $("."+target+"[data-id="+ number +"]").addClass('go');
                $("."+target+"[data-id="+ number +"]").removeClass('goAway');
              }

              //increment
              ++number;

              //continute looping till reached last ID
              if(number <= lastId){return true;}
            });
          }else{
            if($('.'+target).hasClass('go')){
              $('.'+target).addClass('goAway');
              $('.'+target).removeClass('go');
            }else{
              $('.'+target).addClass('go');
              $('.'+target).removeClass('goAway');
            }
          }
        });

        $(document.body).on('appear', '.animatedParent', function(e, $affected){
          var ele = $(this).find('.animated');
          var parent = $(this);


          if(parent.attr('data-sequence') != undefined){

            var firstId = $(this).find('.animated:first').attr('data-id');
            var number = firstId;
            var lastId = $(this).find('.animated:last').attr('data-id');

            $(parent).find(".animated[data-id="+ number +"]").addClass('go');
            number ++;
            delay = Number(parent.attr('data-sequence'));

            $.doTimeout(delay, function(){
              $(parent).find(".animated[data-id="+ number +"]").addClass('go');
              ++number;
              if(number <= lastId){return true;}
            });
          }else{
            ele.addClass('go');
          }

        });

         $(document.body).on('disappear', '.animatedParent', function(e, $affected) {
          if(!$(this).hasClass('animateOnce')){
            $(this).find('.animated').removeClass('go');
           }
         });

         $(window).load(function(){
          $.force_appear();
         });



        // declare the variables
        var $body                 = $('body'),
            $header               = $('header'),
            $no_nav               = $('.no-nav'),
            $navTrigger           = $('.nav-trigger'),
            $nav_trigger_text     = $('.nav-trigger h3'),
            $navLogo              = $('.nav-logo'),
            $globalNav            = $('.nav-primary'),
            $navTopLevel          = $('#menu-global-navigation'),
            $navMenuItem          = $('#menu-global-navigation li.menu-item'),
            $navSubLevel          = $('#menu-global-navigation li .sub-menu'),
            $navSubLevel_active   = $('#menu-global-navigation li.active .sub-menu li.active'),
            $navSubLevelButton    = $('<button class="sub-btn-trigger"></div></button>'),
            $navSubLevelListItem  = $('.sub-nav-trigger'),
            $navSubLevelTrigger   = $('.sub-btn-trigger'),
            $localNavWrap         = $('.local-nav-wrap'),
            $localNavWidth        = $('.local-nav-wrap').innerWidth(),
            $localNav             = $('.local-nav'),
            $authorName           = $('.article-avatar .author a'),
            $scroll_to_content    = $('.scroll-to-main'),
            $document             = $('html,body'),
            $sectionHeight        = $('#section-height'),
            $backToTop_btn        = $('.to-top'),
            $no_btn_footer        = $('footer .footer-wrap'),
            $animationClasses     = $('.animatedParent, .fadeInLeft, .fadeIn, .fadeInUp, .fadeInDown, .fadeInRight'),
            $fuel_updated         = $('#automated-date'),
            $searchTrigger        = $('.search-icon'),
            $searchForm           = $('.global-search'),
            width                 = $(window).width();



      $($searchTrigger).on('click', function(event) {
        event.preventDefault();
        $($searchForm).toggleClass('is-showing');
      });

        /*================================================================
        Fixed Local Navigation (sidebar) Positioning & Width
        ================================================================*/

        function localNavPosition() {
          $linkContainer = $('.local-nav'),
          $localNavWidth = $('.local-nav-wrap').innerWidth(),
          $headerHeight = $('header').outerHeight(),
          $footer = $('footer'),

          $($linkContainer).innerWidth($localNavWidth);

          $($linkContainer).affix({
            offset: {
              top: $headerHeight,
              bottom: function () {
                return (this.bottom = $($footer).outerHeight(true))
              }
            }
          })
        }

        //Target the section-content height and match the local nav's height.
        // $(function() {
        //     $($localNavWrap).matchHeight({
        //         target: $($sectionHeight)
        //     });
        // });




        /*================================================================
        Global Navigation
        ================================================================*/

        // Match the logo height to the nav trigger height to help centre items
        // $(function() {
        //   $($navTrigger).matchHeight({
        //       target: $($navLogo)
        //   });
        // });

        // Add drop-down trigger btns to the mobile menu li
        function addSubNavButtons() {
          $($navSubLevelButton).appendTo($navSubLevelListItem);
          $( '<li class="mobile-pkg-track"><a href="#" type="button" data-toggle="modal" data-target="#track-pkg">Track Your Shipment</a></li>').insertBefore('.nav-primary .mobile-call');
        }
        addSubNavButtons();

        // Navigation Expand
        function navOpen() {

          function navOpenGeneral() {
            $($navTopLevel).slideDown(400);
            $($globalNav).addClass('nav-primary-open');
            $($header).addClass('header-expand');
            $($navMenuItem).addClass('li-open');
            $($no_nav).addClass('nav-open');
            $('.search-icon svg').fadeOut();
          }

          // If the sub-navigation li is active...
          if( $($navSubLevel_active).length > 0 ) {
            $($navSubLevel_active).parent($navSubLevel).slideDown(0).addClass('sub-nav-open');
            $($navSubLevel_active).parent($navSubLevel).siblings($navSubLevelTrigger).addClass('sub-nav-btn-active');
            navOpenGeneral();
          // If no sub-nav li is active
          } else {
            navOpenGeneral();
          }
        }

        // Navigation Close
        function navClose() {
          $($navSubLevel).slideUp().removeClass('sub-nav-open');
          $('.sub-btn-trigger').removeClass('sub-nav-btn-active');
          $($navTopLevel).slideToggle(400);
          $($globalNav).removeClass('nav-primary-open');
          $($header).removeClass('header-expand');
          $($navMenuItem).removeClass('li-open');
          $($no_nav).removeClass('nav-open');
          $('.search-icon svg').fadeIn();
        }

        // Global sub navigation functionality
        function subNav() {
           $('.sub-btn-trigger').click(function(event) {
            // Close any sub-nav menus that may be open.
            $(this).parent('li').siblings().children('ul').slideUp().removeClass('sub-nav-open');
            $(this).parent('li').siblings().children('button').removeClass('sub-nav-btn-active');
            // Open the selected drop-down menu
            $(this).prev('ul').slideToggle().toggleClass('sub-nav-open');
            // If the user clicks on the same drop-down btn, remove the active class
            $(this).toggleClass('sub-nav-btn-active');
         });
        }

        function globalNavigation() {

          //If the user clicks outside of mobile nav.
          $($no_nav).click(function(event) {
            $($navTrigger).click();
          });

          $($navTrigger).click(function(event) {

            // On click, toggle the nav trigger strong between "open" and "close"


            if( $($globalNav).hasClass('nav-primary-open') ) {
              navClose();
              $($nav_trigger_text).text('Menu');
            } else {
              if(($searchForm).hasClass('is-showing')) {
                $($searchForm).removeClass('is-showing');
                setTimeout(function(){ navOpen(); }, 300);
              } else {
                navOpen();
              }

              $($nav_trigger_text).text('Close');
            }
          });
        }

        $(window).resize(function() {
            var width = $(window).width();
            localNavPosition();
            $.fn.matchHeight._update();
            $($linkContainer).affix('checkPosition');

            if( width >= 1200) {
              $($navTopLevel).css({display: 'block'});
              $.fn.matchHeight._update();
              if( $($globalNav).hasClass('nav-primary-open') ) {
                $($globalNav).removeClass('nav-primary-open');
                $($navSubLevel).hide();
              }

            } else {
              if( !$($globalNav).hasClass('nav-primary-open') ) {
                $($navTopLevel).hide();
              }
            }
          });

        localNavPosition();
        globalNavigation();
        subNav();




        /*================================================================
        Blog Avatar Author: Add a <br> tag after the first name
        ================================================================*/

        $($authorName).each(function() {
            var html = $(this).html().split(" ");
            var newhtml = [];

            for(var i=0; i< html.length; i++) {

                if(i>0 && (i%2) === 0)
                    newhtml.push("<br />");

                newhtml.push(html[i]);
            }

            $(this).html(newhtml.join(" "));
        });




        /*================================================================
        Scroll to top from footer and scroll to content on homepage
        ================================================================*/
        $($scroll_to_content).click(function(event) {
          event.preventDefault();
          $($document).animate( { scrollTop:$(this.hash).offset().top } , 1200);
        });




        /*================================================================
        Remove animation effects for mobile by targeting the triggers
        ================================================================*/

       if( width < 992) {
          $($body).find($animationClasses).removeClass('animatedParent fadeInUp fadeIn fadeInLeft fadeInDown fadeInRight');
        }




        /*================================================================
        Add padding to the footer is the back-to-top arrow is not present
        ================================================================*/

        if( !$($backToTop_btn).length > 0 ) {
          $($no_btn_footer).css('padding-top', '5em');
        }



        /*========================================================================================
        Update the fuel surcharge date on the home page and Pricing & Tools page automaticaly
        ========================================================================================*/
        //Create an array that holds all the months of the year
        var month_name=new Array('January','February','March','April', 'May','June','July','August','September', 'October','November','December');
        //Get the current date
        var day = new Date();
        //Get date returns day of the week starting with 0 (Sunday)
        //While today is not Tuesday
        while (day.getDay() != 2) {day = new Date(day.valueOf()-86400000);}

        var fuel_updated_date = month_name[day.getMonth()] + ' ' + day.getDate() + ', '  + day.getFullYear();

        $($fuel_updated).html(fuel_updated_date);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired


        // Some browsers, particularly on IE, so this reruns the matchHeight function
        function resetHeight() {
          var width = $(window).width();
          if( width >= 768 ) {
            $.fn.matchHeight._update()
          }
        }
        setTimeout(resetHeight, 600);
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var $matchHeight = $('.height');
        $($matchHeight).matchHeight({ property: 'min-height' });

        $('.lightbox-hp button').on('click', function(e) {
          e.preventDefault();
          $('.lightbox-hp').fadeOut(500);
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Services landing page
    'services': {
      init: function() {
        // JavaScript to be fired on the services landing page

        /*==========================================================================
        Add animations to each slide and change the timing of the service's slider
        ==========================================================================*/
        width = $(window).width();

        var $carousel = $('#services-carousel');
        $($carousel).carousel({interval: 10000});

        if( width >= 992) {

          function doAnimations( elems ) {
            //Cache the animationend event in a variable
            var animEndEv = 'webkitAnimationEnd animationend';

            elems.each(function () {
              var $this = $(this),
                $animationType = $this.data('animation');
                $this.addClass($animationType).one(animEndEv, function () {
                $this.removeClass($animationType);
              });
            });
          }

          //Variables on page load
          var $firstAnimatingElems = $carousel.find('.item:first').find("[data-animation ^= 'animated']");

          //Initialize carousel
          $($carousel).carousel({interval: 10000});

          //Animate captions in first slide on page load
          doAnimations($firstAnimatingElems);

          //Other slides to be animated on carousel slide event
          $carousel.on('slide.bs.carousel', function (e) {
            var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
            doAnimations($animatingElems);
          });
        }


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Services Default
    'page_template_template_services_default': {
      init: function() {

        // If a list (ul/ol) follows a paragraph, remove the float and margin
        var $service_ul = $('.service-body ul'),
            p           = $('p');

        $($service_ul).prev(p).css({"margin-bottom": "0", "float" : "none" });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Services Default
    'pricing_tools': {
      init: function() {

        // if( $('#28644').is(':visible') ) {
        //   alert("i am visible");

        //   }

        //    $(window).resize(function() {
        //     if( $('#28644').is(':visible') ) {
        //   alert("i am visible");
        //   $('#28644 iframe').contents().find('#menu > li:nth-child(2) > a').click();

        //    }
        //  });

        var iframe = $('#28644 .modal-body > iframe').contents();
        iframe.find("#menu > li:nth-child(2) > a").click(function(){
          alert("test");
      });



      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Accessorial Charges
    'accessorial_charges': {
      init: function() {
        // JavaScript to be fired on the accessorial charges page
        $table                = $('table'),
        $tableRow             = $('.mobile-tr'),
        $tableData            = $('td'),
        $search_chrg          = $('#search-charges'),
        $search_reverse       = $('.search-reverse'),
        $search_input         = $('.search-input'),
        $sort_btn             = $('button.sort'),
        $element              = $(".local-nav-wrap"),
        width                 = $(window).width(),
        lastHeight            = $(".local-nav-wrap").css('height');


        (function() {
        // trim polyfill : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim
        if (!String.prototype.trim) {
          (function() {
            // Make sure we trim BOM and NBSP
            var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
              String.prototype.trim = function() {
                return this.replace(rtrim, '');
              };
            })();
          }

          [].slice.call( document.querySelectorAll( '.input__field--search' ) ).forEach( function( inputEl ) {
            // in case the input is already filled..
            if( inputEl.value.trim() !== '' ) {
              $($search_input).addClass('input--filled');
            }

            function onInputFocus( ev ) {
              $($search_input).addClass('input--filled');
            }

            function onInputBlur( ev ) {
              if( ev.target.value.trim() === '' ) {
                 $($search_input).removeClass('input--filled');
              }
            }

            // events:
            inputEl.addEventListener( 'focus', onInputFocus );
            inputEl.addEventListener( 'blur', onInputBlur );
          } );
        })();

        // Change the reverse search results image on click
        $($search_reverse).click(function() {
           var _this = $(this);
           var current = _this.attr("src");
           var swap = _this.attr("data-swap");
         _this.attr('src', swap).attr("data-swap",current);
        });

        // Rsponsive Table Breakdown
        function responsiveTable() {
          $($table).each(function () {
              var trAcc = $(this).find($tableRow).not("tr:first-child, tr:first-child th"),
                  thRows = trAcc.length,
                  thHead = [];

              $(this).find("tr:first-child td, tr:first-child th").each(function () {
                  headLines = $(this).text();
                  thHead.push(headLines);
              });

              trAcc.each(function () {
                  for (i = 0, l = thHead.length; i < l; i++) {
                      $(this).find($tableData).eq(i + 1).prepend("<h3>" + thHead[i + 1] + "</h3>");
                  }
              });

              trAcc.append('<i class="icon-accordion">+</i>');
              trAcc.click(function () {
                  $(this).siblings().animate({
                          maxHeight: "50px"
                      }, 300).removeClass("accordion-opened").find(".icon-accordion").text("+");


                  if ($(this).hasClass("accordion-opened")) {
                      $(this).animate({
                          maxHeight: "50px"
                      }, 300).removeClass("accordion-opened").find(".icon-accordion").text("+");
                      // $.fn.matchHeight._update()
                  } else {
                      $(this).animate({
                          maxHeight: "1000px"
                      }, 400).addClass("accordion-opened").find(".icon-accordion").text("-");
                      // $.fn.matchHeight._update()
                  }
              });
          });
        };

        function checkForChanges() {
            if( width >= 992 && width <= 1200) {

              if ($element.css('height') !== lastHeight) {
                  $.fn.matchHeight._update()
              }
            setTimeout(checkForChanges, 500);
          }
        }

        $(window).resize(function() {
          checkForChanges();
        });

        checkForChanges();
        responsiveTable();

        $(document).ready(function() {
            $($sort_btn).click();
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Forms Landing Page
    'forms': {
      init: function() {
        // JavaScript to be fired on the forms landing page
        var $formHeight = $('.form-btn-height');

        $(function() {
            $($formHeight).matchHeight();
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Team Member post type
    'single_team': {
      init: function() {
        // JavaScript to be fired on the contact us page

        /*================================================================
        Target the last name of individual in Contact Details, add class
        ================================================================*/
        var $lastWord = $('#lastWord');

        $.fn.lastWord = function() {
          var text = this.text().trim().split(" ");
          var last = text.pop();
          this.html(text.join(" ") + (text.length > 0 ? " <span class='last-word'>" + last + "</span>" : last));
        };

        $($lastWord).lastWord();

      }
    },
    // Single blog post
    'single_post': {
      init: function() {
        // JavaScript to be fired on the contact us page


        var $no_paragraph = $('.blog-content ol li p');

        $($no_paragraph).contents().unwrap();

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
